import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {Navbar, Nav, Container} from "react-bootstrap"
import Logo from "../images/logo.svg";
import arrowWhite from "../images/icons/arrow-white.svg";

const Header = ({siteTitle}) => (
    <header className="bg-primary py-3" id="header-main">
        <Container>
            <Navbar variant="dark" expand="xl" id="site-navbar" className="position-relative px-0 py-1">
                <Link to="/" className="">
                    <Navbar.Brand as="span" className="">
                        <img src={Logo} alt="iCashCars" className="" style={{maxWidth: "180px", width: "180px"}} />
                    </Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className="pt-4 py-lg-3">
                    <Nav className="ml-lg-auto">
                        <Link to="/#howtosell" className="p-0 text-white nav-link" activeStyle={{ borderBottom: "3px solid #f0cd46" }}>
                            How It Works
                        </Link>
                        <Nav.Item className="d-none d-xl-inline-block"><span className="text-white opacity-4 nav-link px-4">|</span></Nav.Item>
                        <Link to="/why-choose-us" className="p-0 text-white nav-link" activeStyle={{ borderBottom: "3px solid #f0cd46" }}>
                            Why Choose Us?
                        </Link>
                        <Nav.Item className="d-none d-xl-inline-block"><span className="text-white opacity-4 nav-link px-4">|</span></Nav.Item>
                        <Link to="/#reviews" className="p-0 text-white nav-link" activeStyle={{ borderBottom: "3px solid #f0cd46" }}>
                            Testimonials
                        </Link>
                        <Nav.Item className="d-none d-xl-inline-block"><span className="text-white opacity-4 nav-link px-4">|</span></Nav.Item>
                        <Link to="/frequently-asked-questions" className="p-0 text-white nav-link sub-nav" activeStyle={{ borderBottom: "3px solid #f0cd46" }}>
                                FAQ
                        </Link>
                        <Nav.Item className="d-none d-xl-inline-block"><span className="text-white opacity-4 nav-link px-4">|</span></Nav.Item>
                        <Link to="/contact" className="p-0 text-white nav-link sub-nav" activeStyle={{ borderBottom: "3px solid #f0cd46" }}>
                            Contact Us
                        </Link>
                    </Nav>
                    <Nav className="ml-lg-auto">
                        <Nav.Item className="mx-xl-3 p-0">
                            <a href="https://traders.icashcars.co.uk/" className="ml-auto btn btn-dark header-cta d-block d-xl-inline-block">Traders
                                <img src={arrowWhite} alt=""/>
                            </a>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}


export default Header
