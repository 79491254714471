/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "../components/header";
import Footer from "../components/footer";


export default function Layout({ children }) {
return (
        <>
            <Header className="fixed-top bg-white pt-lg-4 wrapper"></Header>
            <main>
                {children}
            </main>
            <Footer />

            {/* Pixel */}
            <script type="7ed5858cf2e00ce045b255c9-text/javascript"
                dangerouslySetInnerHTML={{ __html: `
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '2227372240669855');
                    fbq('track', 'PageView');
                `}}
            />
            <noscript
                dangerouslySetInnerHTML={{ __html: `
                    <img height="1" width="1" style="display:none"
                    src='https://www.facebook.com/tr?id=2227372240669855&ev=PageView&noscript=1'/>
                `}}
            />

            {/* Google */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-141844085-1" type="7ed5858cf2e00ce045b255c9-text/javascript"></script>
            <script type="7ed5858cf2e00ce045b255c9-text/javascript"
                dangerouslySetInnerHTML={{ __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'UA-141844085-1');
                `}}
            />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
